import React from 'react';
import './index.css';
import { HomePage } from './pages/home/Home.page';
import { AboutPage } from './pages/about/About.page';
import { TermsPage } from './pages/terms-and-conditions/Terms.page';
import { ContactPage } from './pages/contact-us/Contact.page';
import { DeleteAccountPage } from './pages/delete-account/DeleteAccount.page';
import { FaqPage } from './pages/faq/Faq.page';
import { NotFoundPage } from './pages/notfound/NotFound.page';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ScrollToTop } from './pages/shared/shared';
import { PrivacyPage } from './pages/privacy/Privacy.page';
import { SupportThanksPage } from './pages/support-thanks/SupportThanks.page';
import { BlogPage } from './pages/blog/Blog.page';
import { PostPage } from './pages/post/Post.page';
import { PostEditorPage } from './pages/postEditor/PostEditor.page';
import { BottomBar, TopAppBar } from './pages/shared/components';
import { usePageTracking } from './pages/shared/usePageTracking';

export default function App() {
  return (
    <div className="App-container">
      <BrowserRouter>
        <ScrollToTop />
        <TopAppBar />
        <div style={{ flex: 1 }}>
          <ArtdenRoutes />
        </div>
        <BottomBar />
      </BrowserRouter>
    </div>
  );
}

function ArtdenRoutes(): JSX.Element {
  usePageTracking();

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/terms-and-conditions" element={<TermsPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/delete-account" element={<DeleteAccountPage />} />
      <Route path="/faq" element={<FaqPage />} />
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/support-thanks" element={<SupportThanksPage />} />
      <Route path="/blog" element={<BlogPage />} />
      <Route path="/post" element={<PostPage />} />
      <Route path="/internal/blog" element={<PostEditorPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
