export class DateTimeUtil {
  static now(): number {
    return Date.now();
  }

  static formatDateFromTimestamp(timestamp: number, locale: string = 'en-US'): string {
    const date = new Date(timestamp);
    const now = new Date();

    const currentYear = now.getFullYear();
    const timestampYear = date.getFullYear();

    const options: Intl.DateTimeFormatOptions = {
      month: 'long',
      day: 'numeric',
    };

    if (timestampYear !== currentYear) {
      options.year = 'numeric';
    }

    return date.toLocaleDateString(locale, options);
  }
}
