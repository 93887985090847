import React from 'react';
import './SupportThanks.page.css';
import '../shared/shared.css';
import { Spacer20, Spacer5 } from '../shared/shared';
import { Link } from 'react-router-dom';
import { useIsMobile } from '../shared/useIsMobile';

export function SupportThanksPage(): JSX.Element {
  return (
    <div>
      <SupportThanksPanel />
    </div>
  );
}

function SupportThanksPanel(): JSX.Element {
  const isMobile = useIsMobile();
  return (
    <div
      className={`${
        isMobile ? 'BorderSizing Padding-Horizontal-5' : 'FillWidth Flex FlexJustifyCenter'
      }`}
    >
      <div className={`${isMobile ? '' : 'MaxColumnWidth'}`}>
        {isMobile ? <Spacer5 /> : <Spacer20 />}
        <span className="Panel-header">Thank you!</span>
        <p>Thank you for submitting your support request!</p>
        <p>Someone should be reaching out to you soon for assistance.</p>
        <Spacer5 />
        <Link to="/">
          <button
            className={`button-style Padding-Right-10 Padding-Left-10 ${
              isMobile ? 'FillWidth' : ''
            }`}
            type="button"
          >
            Return Home
          </button>
        </Link>
        <Spacer20 />
      </div>
    </div>
  );
}
