import React, { useEffect, useState } from 'react';
import '../shared/shared.css';
import { Spacer4, Spacer20, Spacer5 } from '../shared/shared';
import { AsyncState, AsyncStatus, DateTimeUtil, Post, TextUtil } from 'artden-common';
import { useIsMobile } from '../shared/useIsMobile';
import { useQuery } from '../shared/useQuery';
import { NotFoundPage } from '../notfound/NotFound.page';

export function PostPage(): JSX.Element {
  const query = useQuery();
  const id = query.get('id');
  if (id == null) return <NotFoundPage />;
  const idNum = parseInt(id);
  if (isNaN(idNum)) return <NotFoundPage />;

  return (
    <div>
      <PostPanel id={idNum} />
    </div>
  );
}

interface PostPageProps {
  id: number;
}

function PostPanel({ id }: PostPageProps): JSX.Element {
  const isMobile = useIsMobile();
  const [state, setState] = useState<AsyncState<Post>>({ status: AsyncStatus.Loading });

  const fetchData = () => {
    fetch('/api/post?id=' + id)
      .then((response) => {
        if (!response.ok) {
          throw Error('Error loading data');
        } else {
          return response.json();
        }
      })
      .then((responseData: Post) => {
        setState({ status: AsyncStatus.Loaded, data: responseData });
      })
      .catch((e: Error) => {
        setState({ status: AsyncStatus.Error, error: e });
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div
      className={`BorderSizing ${
        isMobile ? 'Padding-Horizontal-5' : 'FillWidth Flex FlexJustifyCenter'
      }`}
    >
      <div className={`${isMobile ? '' : 'MaxColumnWidth'}`}>
        {isMobile ? <Spacer5 /> : <Spacer20 />}
        <div className={`BorderSizing ${isMobile ? '' : 'GreyBorder Padding-15'}`}>
          <PostContent state={state} />
        </div>
        <Spacer20 />
      </div>
    </div>
  );
}

interface PostContentProps {
  state: AsyncState<Post>;
}

function PostContent({ state }: PostContentProps): JSX.Element {
  switch (state.status) {
    case AsyncStatus.Loading:
      return (
        <div className="FillWidth Flex Flex FlexJustifyCenter Padding-10">
          <div className="Shared-LoadingSpinner"></div>
        </div>
      );
    case AsyncStatus.Loaded: {
      return <PostContentRender post={state.data} />;
    }
    case AsyncStatus.Error:
      return <div>Error: {state.error.message}</div>;
  }
}

interface PostEditorProps {
  post: Post;
}

export function PostContentRender({ post }: PostEditorProps): JSX.Element {
  return (
    <div>
      <div className="FlexRow FlexAlignCenter">
        <img
          className="Size-8 BorderSizing"
          alt="Author"
          src={`/images/profile_images/${post.profileImage}`}
        />
        <Spacer4 />
        <div className="TextSize-Caption">{`${post.author} ∙ ${DateTimeUtil.formatDateFromTimestamp(
          post.createdAt,
        )} ∙ ${TextUtil.estimateReadingTime(post.wordCount)}`}</div>
      </div>
      <div className="TextSize-Header Padding-Vertical-4">{post.title}</div>
      <div className="TextSize-Body" dangerouslySetInnerHTML={{ __html: post.body }}></div>
    </div>
  );
}
