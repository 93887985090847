import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import './shared.css';
import { useLocation } from 'react-router-dom';

export function Spacer1(): JSX.Element {
  return <div className="Size-1"></div>;
}

export function Spacer2(): JSX.Element {
  return <div className="Size-2"></div>;
}

export function Spacer3(): JSX.Element {
  return <div className="Size-3"></div>;
}

export function Spacer4(): JSX.Element {
  return <div className="Size-4"></div>;
}

export function Spacer5(): JSX.Element {
  return <div className="Size-5"></div>;
}

export function Spacer6(): JSX.Element {
  return <div className="Size-6"></div>;
}

export function Spacer7(): JSX.Element {
  return <div className="Size-7"></div>;
}

export function Spacer8(): JSX.Element {
  return <div className="Size-8"></div>;
}

export function Spacer9(): JSX.Element {
  return <div className="Size-9"></div>;
}

export function Spacer10(): JSX.Element {
  return <div className="Size-10"></div>;
}

export function Spacer11(): JSX.Element {
  return <div className="Size-11"></div>;
}

export function Spacer12(): JSX.Element {
  return <div className="Size-12"></div>;
}

export function Spacer13(): JSX.Element {
  return <div className="Size-13"></div>;
}

export function Spacer14(): JSX.Element {
  return <div className="Size-14"></div>;
}

export function Spacer15(): JSX.Element {
  return <div className="Size-15"></div>;
}

export function Spacer16(): JSX.Element {
  return <div className="Size-16"></div>;
}

export function Spacer17(): JSX.Element {
  return <div className="Size-17"></div>;
}

export function Spacer18(): JSX.Element {
  return <div className="Size-18"></div>;
}

export function Spacer19(): JSX.Element {
  return <div className="Size-19"></div>;
}

export function Spacer20(): JSX.Element {
  return <div className="Size-20"></div>;
}

interface ExpandableRowProps {
  title: string;
  children: React.ReactNode;
}

export function ExpandableRow({ title, children }: ExpandableRowProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(isOpen ? contentRef.current.scrollHeight : 0);
    }
  }, [isOpen]);

  return (
    <div className={`Shared-ExpandableRow ${isOpen ? 'open' : ''}`}>
      <div
        className="Shared-ExpandableRow-header BorderSizing Padding-Vertical-4 TextSize-SectionHeader"
        onClick={toggleOpen}
      >
        {title}
        <span className={`Shared-ExpandableRow-arrow ${isOpen ? 'open' : ''}`}>
          <b>&rsaquo;</b>
        </span>
      </div>
      <div
        ref={contentRef}
        className={` Shared-ExpandableRow-content${isOpen ? 'open' : ''}`}
        style={{
          height: isOpen ? `${contentHeight}px` : '0',
          overflow: 'hidden',
          transition: 'height 0.3s ease',
        }}
      >
        {children}
      </div>
    </div>
  );
}

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

interface ImageUploadProps {
  onChooseImage: (data: string) => void;
}

export function ImageUpload({ onChooseImage }: ImageUploadProps): JSX.Element {
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  // Handle file selection
  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      // Create a preview URL
      const reader = new FileReader();
      reader.onload = (loadEvent: ProgressEvent<FileReader>) => {
        const result = loadEvent.target?.result;
        if (typeof result === 'string') {
          setPreviewUrl(result);
          onChooseImage(result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <input type="file" accept="image/*" onChange={handleImageChange} />
      {previewUrl && (
        <img src={previewUrl} alt="Preview" style={{ width: '100px', height: 'auto' }} />
      )}
    </div>
  );
}

export function Divider(): JSX.Element {
  return <hr style={{ background: 'red' }}></hr>;
}
