export enum AsyncStatus {
  Loading = 'LOADING',
  Loaded = 'LOADED',
  Error = 'ERROR',
}

interface AsyncDataState {
  status: AsyncStatus;
}

export interface LoadingState extends AsyncDataState {
  status: AsyncStatus.Loading;
}

export interface LoadedState<T> extends AsyncDataState {
  status: AsyncStatus.Loaded;
  data: T;
}

export interface ErrorState extends AsyncDataState {
  status: AsyncStatus.Error;
  error: Error;
}

export type AsyncState<T> = LoadingState | LoadedState<T> | ErrorState;
