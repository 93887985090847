import React from 'react';
import './Privacy.page.css';
import '../shared/shared.css';
import { Spacer10, Spacer20, Spacer5 } from '../shared/shared';
import { Link } from 'react-router-dom';
import { useIsMobile } from '../shared/useIsMobile';

export function PrivacyPage(): JSX.Element {
  return (
    <div>
      <PrivacyPanel />
    </div>
  );
}

function PrivacyPanel(): JSX.Element {
  const isMobile = useIsMobile();
  return (
    <div
      className={`${
        isMobile ? 'BorderSizing Padding-Horizontal-5' : 'FillWidth Flex FlexJustifyCenter'
      }`}
    >
      <div className={`${isMobile ? '' : 'MaxColumnWidth'}`}>
        {isMobile ? <Spacer5 /> : <Spacer20 />}
        <span className="Panel-header">Privacy Policy</span>
        <br></br>
        <span className="Bold">Last updated 4/11/2023</span>
        <br></br>
        <Spacer10 />
        <span className="Panel-subheader">Our Commitment to Privacy</span>
        <p>
          Your privacy is important to us. To better protect your privacy, we provide this notice
          explaining our privacy practices and the choices you can make about the way your
          information is collected and used. To make this notice easy to find, we make it available
          on our website, and at every point where your information may be requested.
        </p>
        <span className="Panel-subheader">Information Collection and Use</span>
        <p>
          On some pages of our website or app (collectively, the “Services”), you may provide your
          name, email address, as well as create a username and password. We encourage you to submit
          this information so that we can provide you a more personalized experience with our
          Services. We may use your information for the following purposes:
        </p>
        <ul>
          <li>To provide the Services to you;</li>
          <li>To send you communications regarding the Services;</li>
          <li>To notify you about special promotions or offers that may be of interest to you;</li>
          <li>To monitor, detect and prevent fraud or other illegal activity;</li>
          <li>To process payments;</li>
          <li>To evaluate how you and other users interact with our Services;</li>
          <li>
            To secure and debug the Services, and to identify and repair errors that impair existing
            intended functionality;
          </li>
          <li>To defend ourselves in the event of legal claims or litigation; and</li>
          <li>To comply with legal and regulatory obligations.</li>
        </ul>
        <p>
          We do not sell your personal information. However, we may share personal information with
          service partners or other third parties (collectively, “Service Providers”). Examples of
          Service Providers include Stripe, our payment processor, customer service and support
          providers, as well as web hosting and development companies. Our policy is to require our
          Service Providers to keep your personal information confidential and to use personal
          information only to perform functions for us.
        </p>
        <p>
          We reserve the right to disclose your information to respond to authorized information
          requests from governmental and judicial authorities, where we believe the disclosure is
          necessary in order to comply with a regulatory requirement, judicial proceeding, court
          order, governmental request or legal process served on us, or to protect the safety,
          rights, or property of our customers or the public, and to exercise or defend our legal
          rights.
        </p>
        <span className="Panel-subheader">Choice/Opt-out</span>
        <p>
          If you no longer wish to receive communications from us, you may opt-out of receiving them
          by following the instructions included in the communication.
        </p>
        <p>
          Depending on your jurisdiction, applicable law may entitle you to additional consumer
          rights, including the right (with certain limitations and exceptions) to the following:
        </p>
        <ul>
          <li>
            The right to know which categories and/or specific pieces of personal information we
            have collected about you, including whether your personal information is sold or
            disclosed, and with whom your personal information was shared;
          </li>
          <li>
            The right to access a copy, correct inaccuracies, or request deletion of the personal
            information we retain about you; and
          </li>
          <li>The right to opt of the processing of your personal data.</li>
        </ul>
        <p>
          We reserve the right to verify your identity in connection with any requests regarding
          your personal information to help ensure that we allow only those individuals or their
          authorized representatives to exercise rights with respect to that information. We will
          endeavor to respond to requests received within a reasonable time period, no later than
          forty-five (45) days after receipt. If we need more than forty-five (45) days to respond,
          we will inform you of the reason for the delay. We also may deny your request if we cannot
          verify your identity. When we deny your request, we will provide an explanation of the
          reasons for the denial.
        </p>
        <p>
          We will not restrict or deny you access to the Services because of choices and requests
          you make in connection with your personal information. However, certain choices may affect
          our ability to provide the Services. For example, if you sign up to receive marketing
          communications by email, then ask us to delete all of your information, we will be unable
          to send you marketing communications.
        </p>
        <p>
          As is true of most websites, we may gather certain information automatically and store it
          in log files. This information may include users’ IP addresses, browser type,
          referring/exit pages, operating system, date/time stamp, and/or clickstream data. We use
          this information, which does not identify individual users, to analyze trends, to
          administer the website, to track users’ movements around the site, and to gather
          demographic information about our user base as a whole.
        </p>
        <p>
          We may also use tracking pixels, web beacons, Google Analytics, and other similar
          technologies to improve your user experience with the Services, to compile statistics,
          manage our website, to provide you with more relevant advertisements based on your
          interests, analyze trends, and to otherwise administer and improve the Services.
        </p>
        <span className="Panel-subheader">Third Party Advertisers</span>
        <p>
          We do not currently employ any third party advertisers, but we reserve the right to do so
          at any time in the future, in our sole discretion.
        </p>
        <span className="Panel-subheader">Third party Sites</span>
        <p>
          Our website may contain links to third party websites, such as from our partners,
          advertisers, affiliates and social network sites. Please note that these websites have
          their own privacy notices. You should always check any third party privacy notices before
          you submit any personal information to those websites.
        </p>
        <span className="Panel-subheader">Security</span>
        <p>
          The security of your information is important to us. We follow generally-accepted industry
          standards to protect information submitted to us, both during transmission and once we
          receive it. No method of transmission over the internet, or method of electronic storage,
          is 100% secure, however. Therefore, while we strive to use commercially-acceptable means
          to protect your information, we cannot guarantee its absolute security.
        </p>
        <span className="Panel-subheader">Children</span>
        <p>
          We do not knowingly collect or use any information from minors under the age of 13. We do
          not knowingly allow children to communicate with us, or use any of our Services. If you
          are a parent and believe that your child has provided us with any information, please
          contact us, and we will work with you to address this issue.
        </p>
        <span className="Panel-subheader">Business Transitions</span>
        <p>
          In the event we go through a business transition, such as a merger, acquisition by another
          company, or sale of all or a portion of our assets, your information will likely be among
          the assets transferred.
        </p>
        <span className="Panel-subheader">Contact Us</span>
        <p>
          If you have questions about your information, please contact us by clicking on the
          “Contact” link on our website, or by e-mailing us at{' '}
          <a href="mailto:artden.contact@gmail.com">artden.contact@gmail.com</a> or visit{' '}
          <Link to="/contact">https://www.artdenapp.com/contact</Link>.
        </p>
        <p>
          In your email, please include the email address used for registration (if applicable), and
          a detailed explanation of your request. We will do our best to respond to all reasonable
          requests in a timely manner.
        </p>
        <span className="Panel-subheader">Changes to this Privacy Policy</span>
        <p>
          We reserve the right to modify this privacy statement at any time, so please review it
          frequently.
        </p>
        <Spacer20 />
      </div>
    </div>
  );
}
