import React from 'react';
import './About.page.css';
import '../shared/shared.css';
import { Spacer10, Spacer20, Spacer5 } from '../shared/shared';
import { useIsMobile } from '../shared/useIsMobile';

export function AboutPage(): JSX.Element {
  return (
    <div>
      <AboutPanel />
    </div>
  );
}

function AboutPanel(): JSX.Element {
  const isMobile = useIsMobile();
  return isMobile ? (
    <div>
      <AboutCopy />
      <AboutImage />
    </div>
  ) : (
    <div className="FlexRow">
      <AboutImage />
      <AboutCopy />
    </div>
  );
}

function AboutImage(): JSX.Element {
  const isMobile = useIsMobile();
  const backgroundStyle = {
    backgroundImage: `url(/images/about.webp)`,
  };
  return isMobile ? (
    <img className="FillWidth" alt="About" src={`/images/about.webp`} />
  ) : (
    <div style={backgroundStyle} className="FlexEqualWeight About-leftImage"></div>
  );
}

function AboutCopy(): JSX.Element {
  const isMobile = useIsMobile();
  const paddingClass = isMobile ? 'Padding-Horizontal-5' : 'Panel-paddingRight Padding-Left-8';
  return (
    <div className="BorderSizing FlexEqualWeight">
      <div className={paddingClass}>
        {isMobile ? <Spacer5 /> : <Spacer20 />}
        <span className="Panel-header">About ArtDen</span>
        <p>
          ArtDen was created out of necessity, before 2020 when I started this project, I was a
          struggling digital artist trying to find my stride. I began searching for an app where I
          could post my work and consistently be discovered while digging into an artist community
          but I couldn&apos;t find one! I resorted to posting on Reddit, Twitter, Instagram for
          visibility and got quite a few likes but no orders. This little deviate hatchling was my
          most popular post, I was so proud! I created a website to direct potential customers to
          and a Fiverr account to test the gig marketplace. Eventually, I did get my first
          commission, through one of my close friends at the time.
        </p>

        <p>
          The frustration of only finding one customer in over a year drove me to create my own app!
          I work full time as an Android Developer and decided to leverage my skills to create a
          Flutter app for both platforms. It&apos;s still in its early stages, but my goal is to
          have a safe and consistent hub for artists to be discovered and get orders and customers
          to discover new artists and create epic new creations.
        </p>

        <p>
          While I&apos;m a team of one working a full time job, I will work to make this the best
          experience possible for you!
        </p>
        <Spacer10 />
      </div>
    </div>
  );
}
