export class TextUtil {
  static ellipsizeText(text: string, maxWords: number): string {
    const words = text.split(/\s+/); // Split by whitespace
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(' ') + '...';
    }
    return text;
  }

  static estimateReadingTime(wordCount: number): string {
    const wordsPerMinute = 200; // Average reading speed
    const minutes = wordCount / wordsPerMinute;
    const roundedMinutes = Math.ceil(minutes); // Round up to the nearest minute

    return `${roundedMinutes} min`;
  }

  static removeHtmlFromString(htmlString: string): string {
    return htmlString.replace(/<[^>]*>/g, ' ');
  }

  static countWordsInHtml(htmlString: string): number {
    const strippedString = TextUtil.removeHtmlFromString(htmlString);

    // Replace HTML entities with spaces
    const unescapedString = strippedString.replace(/&[a-zA-Z0-9#]+;/g, ' ');

    // Split by whitespace and filter out empty strings
    const words = unescapedString
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0);

    // Return the number of words
    return words.length;
  }
}
