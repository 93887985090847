import React, { useEffect, useState } from 'react';
import './Blog.page.css';
import '../shared/shared.css';
import { Spacer2, Spacer20, Spacer3, Spacer5 } from '../shared/shared';
import { AsyncState, AsyncStatus, DateTimeUtil, Post, TextUtil } from 'artden-common';
import { useIsMobile } from '../shared/useIsMobile';
import { Link } from 'react-router-dom';

export function BlogPage(): JSX.Element {
  return (
    <div>
      <BlogPanel />
    </div>
  );
}

function BlogPanel(): JSX.Element {
  const isMobile = useIsMobile();
  const [state, setState] = useState<AsyncState<Post[]>>({ status: AsyncStatus.Loading });
  const [query, setQuery] = useState('');

  const fetchData = () => {
    fetch('/api/posts')
      .then((response) => {
        if (!response.ok) {
          throw Error('Error loading data');
        } else {
          return response.json();
        }
      })
      .then((responseData: Post[]) => {
        setState({ status: AsyncStatus.Loaded, data: responseData });
      })
      .catch((e: Error) => {
        setState({ status: AsyncStatus.Error, error: e });
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div
      className={`${
        isMobile ? 'BorderSizing Padding-Horizontal-5' : 'FillWidth FlexColumn FlexAlignCenter'
      }`}
    >
      <div className={`${isMobile ? '' : 'MaxColumnWidth'}`}>
        {isMobile ? <Spacer5 /> : <Spacer20 />}
        <BlogHeader query={query} setQuery={setQuery} />
        <div>
          <BlogContent state={state} query={query} />
        </div>
        <Spacer20 />
      </div>
    </div>
  );
}

interface BlogSearchBarProps {
  query: string;
  setQuery: (query: string) => void;
}

function BlogHeader({ query, setQuery }: BlogSearchBarProps): JSX.Element {
  const isMobile = useIsMobile();
  return isMobile ? (
    <div className="FillWidth FlexColumn">
      <span className="TextSize-SectionHeader">All posts</span>
      <div className="FillWidth Flex FlexAlignCenter">
        <BlogSearchBar query={query} setQuery={setQuery} />
      </div>
    </div>
  ) : (
    <div className="FillWidth FlexRow FlexAlignCenter">
      <span className="TextSize-SectionHeader">All posts</span>
      <div className="Flex FlexEqualWeight" />
      <div className="FlexRow FlexAlignCenter MaxWidth-350px MinWidth-350px FillWidth">
        <BlogSearchBar query={query} setQuery={setQuery} />
      </div>
    </div>
  );
}

function BlogSearchBar({ query, setQuery }: BlogSearchBarProps): JSX.Element {
  return (
    <>
      <svg
        className="Size-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx="11" cy="11" r="8"></circle>
        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
      </svg>
      <Spacer3 />
      <input
        className="FlexEqualWeight"
        type="text"
        id="search"
        name="search"
        placeholder="Search..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
    </>
  );
}

interface BlogContentProps {
  state: AsyncState<Post[]>;
  query: string;
}

function BlogContent({ state, query }: BlogContentProps): JSX.Element {
  switch (state.status) {
    case AsyncStatus.Loading:
      return (
        <div className="FillWidth Flex Flex FlexJustifyCenter Padding-10">
          <div className="Shared-LoadingSpinner"></div>
        </div>
      );
    case AsyncStatus.Loaded: {
      const filteredPosts = state.data.filter((post) =>
        post.body.toLowerCase().includes(query.toLowerCase()),
      );
      return filteredPosts.length > 0 ? (
        <>
          {filteredPosts.map((post) => (
            <BlogRow key={post.id} post={post} />
          ))}
        </>
      ) : (
        <BlogNoResults />
      );
    }
    case AsyncStatus.Error:
      return <div>Error: {state.error.message}</div>;
  }
}

function BlogNoResults(): JSX.Element {
  return (
    <div className="FillWidth Flex FlexJustifyCenter Padding-20 BorderSizing">
      <span className="TextSize-SectionHeader">(No Results)</span>
    </div>
  );
}

interface BlogRowProps {
  post: Post;
  imageOverride?: string;
}

export function BlogRow({ post, imageOverride }: BlogRowProps): JSX.Element {
  const isMobile = useIsMobile();
  return (
    <div
      className={`Flex FlexWrap FillWidth GreyBorder Margin-Vertical-8 FlexAlignCenter BorderSizing ${
        isMobile ? '' : 'MinWidth-350px'
      }`}
    >
      <div className="FlexWrapHalf BorderSizing FillWidth">
        <Link to={`/post?id=${post.id}`}>
          <img
            className="FillWidth BorderSizing"
            alt={post.title}
            src={imageOverride ? imageOverride : `${process.env.PUBLIC_URL}/images/${post.id}.png`}
          />
        </Link>
      </div>
      <div
        className={`FlexWrapHalf BorderSizing FillWidth Padding-8 ${
          isMobile ? '' : 'MinWidth-350px'
        }`}
      >
        <div className="FlexRow FlexAlignCenter">
          <img
            className="Size-8 BorderSizing"
            alt="Author"
            src={`/images/profile_images/${post.profileImage}`}
          />
          <Spacer2 />
          <div className="FlexEqualWeight">
            <div className="TextSize-Caption">{post.author}</div>
            <div className="TextSize-Caption">{`${DateTimeUtil.formatDateFromTimestamp(
              post.createdAt,
            )} ∙ ${TextUtil.estimateReadingTime(post.wordCount)}`}</div>
          </div>
        </div>
        <Link className="Normal BlackColor" to={`/post?id=${post.id}`}>
          <div className="TextSize-Header">{TextUtil.ellipsizeText(post.title, 12)}</div>
        </Link>
        <Spacer2 />
        <Link className="Normal BlackColor" to={`/post?id=${post.id}`}>
          <div className="TextSize-Body">
            {TextUtil.ellipsizeText(TextUtil.removeHtmlFromString(post.body), 40)}
          </div>
        </Link>
      </div>
    </div>
  );
}
