import React from 'react';
import './Faq.page.css';
import '../shared/shared.css';
import { Spacer20, Spacer5 } from '../shared/shared';
import { useIsMobile } from '../shared/useIsMobile';
import { ExpandableRowV2 } from '../shared/components';

export function FaqPage(): JSX.Element {
  return (
    <div>
      <FaqPanel />
    </div>
  );
}

function FaqPanel(): JSX.Element {
  const isMobile = useIsMobile();
  return (
    <div
      className={`FlexColumn ${
        isMobile ? 'BorderSizing Padding-Horizontal-5' : 'FillWidth Flex FlexAlignCenter'
      }`}
    >
      <div className={`FlexColumn ${isMobile ? '' : 'MaxColumnWidth'}`}>
        {isMobile ? <Spacer5 /> : <Spacer20 />}
        <span className="Panel-header CenterText">General FAQs</span>
        <ExpandableRowV2 title="Where can I download the app?">
          <div>
            ArtDen can be downloaded on Android or iOS devices using these links:
            <ul>
              <li>
                <a
                  href="https://play.google.com/store/apps/details?id=com.artden.app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Google Play Store
                </a>
              </li>
              <li>
                <a
                  href="https://apps.apple.com/us/app/artden/id1659234106"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  iOS App Store
                </a>
              </li>
            </ul>
          </div>
        </ExpandableRowV2>
        <ExpandableRowV2 title="Does ArtDen have any content restrictions?">
          <div>
            <p>
              Most content is allowed on ArtDen. If it&apos;s drawn or painted, it&apos;s probably
              allowed. allowed. We do not allow realistic depictions or photographs of graphic
              pornography. We do have an NSFW tag that we request you utilize to respect users who
              may not want to view NSFW commission images.
            </p>
            <p>
              To toggle this on, navigate to your Profile and select “Account”. Under “Settings” you
              can toggle NSFW content on or off.
            </p>
            <p>
              If you aren&apos;t sure if your content is allowed, please reach out to support! If
              your If your content is not allowed or the NSFW tag is not used, it may impact your
              account.
            </p>
          </div>
        </ExpandableRowV2>
        <ExpandableRowV2 title="Does ArtDen allow AI generated art or NFTs?">
          <div>
            <p>
              ArtDen currently does not allow AI generated art and NFTs. We believe that real art
              comes from real people!
            </p>
          </div>
        </ExpandableRowV2>
        <ExpandableRowV2 title="What fees does ArtDen charge?">
          <div>
            <p>
              ArtDen does charge a platform fee for the services that we provide, such as: image
              hosting, order management tools, customer service, security and many others! This fee
              is only charged, though, if a commission is purchased.
            </p>
            <p>
              Artist fee: 5% platform fee on each order. Customer: 5% (Small order fee of an
              additional $2 if commission price is less than $100)
            </p>
            <p>ArtDen pays any payment processing fees from Stripe.</p>
            <p>
              For example: if you charge $50 for a commission, your customer will pay $54.50 and you
              will receive $47.50. If you charge $200 for a commission, your customer will pay $210
              and you will receive $190.
            </p>
          </div>
        </ExpandableRowV2>
        <ExpandableRowV2 title="What countries does ArtDen support?">
          <div>
            <p>ArtDen is currently only supported in the following countries:</p>
            <ul>
              <li>United States</li>
              <li>Canada</li>
            </ul>
            <p>
              We have plans to expand! We are focusing on requested countries first, so make sure to
              let us know if you want to use ArtDen in your country!
            </p>
          </div>
        </ExpandableRowV2>
        <ExpandableRowV2 title="How does ArtDen handle refunds?">
          <p>
            If a cancellation is initiated on an active order and the other party accepts it, then a
            refund will be issued immediately. This can take a few days for your bank to transfer
            the money, and you will have to contact them for more information on timing.
          </p>
          <p>
            If the other party in your order does not accept a cancellation, please try reaching out
            to the other party. Most times, you can come to a compromise! If you’re unable to reach
            the other party, or if they are unable to work with you, then please reach out to us via
            Discord or through the Contact Support button in the app.
          </p>
          <p>
            Our Support Team can issue a refund, but it is on a case-by-case basis. Refunds through
            Support depend on the work that has been completed by the Artist; we want to be fair!
          </p>
        </ExpandableRowV2>
        <ExpandableRowV2 title="What should I do if I find a commission or account breaking ArtDen’s rules?">
          <div>
            <p>
              Community reports help keep our platform safe! If you find a commission that breaks
              our guidelines, you can report it by viewing it, tapping the three dots in the top
              right and tapping “Report commission”. We review reports and take action ASAP. When
              you’re making a report, please include as much information as you can to help expedite
              the process.
            </p>
            <p>
              If you would no longer like to see a commission, you can block the user and they will
              no longer be visible on your Dashboard or in any searches. You can do this by tapping
              on their name in the commission post, then tapping the three dots and tapping “Block”.
            </p>
          </div>
        </ExpandableRowV2>
        <ExpandableRowV2 title="How can I contact ArtDen support to report a bug, submit feedback or ask for help?">
          <div>
            <p>
              You can contact us at ArtDen by reaching out through the contact form in your profile
              or by reaching out to us on our{' '}
              <a href="https://discord.gg/BCf4Y2PXdQ" target="_blank" rel="noopener noreferrer">
                Discord
              </a>{' '}
              in the support section.
            </p>
          </div>
        </ExpandableRowV2>
        {isMobile ? <Spacer5 /> : <Spacer20 />}
        <span className="Panel-header CenterText">Artist FAQs</span>
        <ExpandableRowV2 title="How long does it take Stripe to validate my account?">
          <div>
            <p>
              After you submit your information to Stripe, you may see a message on your Artist Hub
              saying that it is processing. While this process is normally quite fast, it can take
              some time. We suggest you give it 24 hours for verification to go through; this
              verification is handled by Stripe so we are unable to provide more exact estimates. If
              you are still not verified after 24 hours, though, please reach out to our Support
              Team so we can double-check your account and make sure there aren’t any errors!
            </p>
          </div>
        </ExpandableRowV2>

        <ExpandableRowV2 title="Do I need to worry about taxes on my commissions?">
          <div>
            <p>ArtDen collects any applicable sales tax for the transaction on your behalf.</p>
            <p>
              For income tax, Stripe will send out a 1099 with your income in January, either by
              mail or electronically through your Express Dashboard if you opt in for paperless
              sending. If you have any questions about how to process the 1099, we recommend you
              speak to a tax advisor.
            </p>
          </div>
        </ExpandableRowV2>

        <ExpandableRowV2 title="How does ArtDen send payments to me? How long does it take?">
          <div>
            <p>
              Commissions are paid for upfront through Stripe before you begin work. When customers
              pay for a commission, the amount is sent to your Stripe account and you will receive
              it on the next payout which occurs every 2 days.
            </p>
          </div>
        </ExpandableRowV2>

        <ExpandableRowV2 title="Does ArtDen have a file size limit for uploads?">
          <div>
            <p>
              We don’t have any file size limits at ArtDen, so you can create your final product
              with no size restrictions. Please keep in mind, though, to help with space on our
              database, all example images are compressed to 10% of the original size. This allows
              us to deliver commissions at 100% of the size you create while keeping costs for our
              storage database down.
            </p>
          </div>
        </ExpandableRowV2>

        <ExpandableRowV2 title="I don't see a category for my art, what do I do?">
          <div>
            <p>
              If you don’t see an exact match for your art, that’s okay! Just place your art in the
              closest category and add a description and tags. Anything you add to your
              description/tags is searchable, which means that customers can narrow it down
              themselves if they’re looking for something super specific.
            </p>
          </div>
        </ExpandableRowV2>

        <ExpandableRowV2 title="Is traditional or non-digital art allowed?">
          <div>
            <p>
              ArtDen allows art of any type, including traditionally made art! Unfortunately,
              though, we are a digital only service, which means that we can only deliver the art if
              you scan it in and send it digitally. You can always work shipping out with your
              customer on your own, though!
            </p>
          </div>
        </ExpandableRowV2>

        <ExpandableRowV2 title="Is there a way for my customers to tip me after their commission is delivered?">
          <div>
            <p>
              Yes! Once the commission is completed, customers can send additional money as a tip
              via Stripe. Any tips are not charged a fee by ArtDen, however Stripe will still charge
              the processing fee.
            </p>
          </div>
        </ExpandableRowV2>

        <ExpandableRowV2 title="What payment platforms does ArtDen support?">
          <div>
            <p>
              Currently, the only payment platform that ArtDen supports is Stripe. There are plans
              to add more as the app continues to develop, though! If you have a preferred payment
              platform, drop us a suggestion and we’ll see if we can implement it on ArtDen in the
              future.
            </p>
          </div>
        </ExpandableRowV2>
        {isMobile ? <Spacer5 /> : <Spacer20 />}
        <span className="Panel-header CenterText">Customer FAQs</span>
        <ExpandableRowV2 title="What can I do to support an artist I like if I don’t want to purchase a commission right now?">
          <div>
            <p>
              If you’re unable or don’t want to purchase a commission right now but still want to
              support our artists, there are several ways to do so! On the ArtDen app, you can watch
              artists to see when they post new commissions and new artwork. The amount of people
              watching an artist is visible on the app! You can also share the artist through social
              media, and like their art posts on our Discord server.
            </p>
          </div>
        </ExpandableRowV2>
        <Spacer20 />
      </div>
    </div>
  );
}
