import React from 'react';
import './Terms.page.css';
import '../shared/shared.css';
import { Spacer20, Spacer5 } from '../shared/shared';
import { useIsMobile } from '../shared/useIsMobile';

export function TermsPage(): JSX.Element {
  return (
    <div>
      <TermsCopy />
    </div>
  );
}

function TermsCopy(): JSX.Element {
  const isMobile = useIsMobile();
  return (
    <div
      className={`${
        isMobile ? 'BorderSizing Padding-Horizontal-5' : 'FillWidth Flex FlexJustifyCenter'
      }`}
    >
      <div className={`${isMobile ? '' : 'MaxColumnWidth'}`}>
        {isMobile ? <Spacer5 /> : <Spacer20 />}
        <Spacer5 />
        <div className="Panel-header CenterText">TERMS OF USE</div>
        <p>
          This Terms of Use Agreement (“Agreement”) is made by and between ArtDen, LLC, a Washington
          state limited liability company (“we,” “us,” or “our”) and you (“you,” or “your”). This
          Agreement contains the terms and conditions that govern your use of our website and app
          (collectively, the “Services”). This document contains important information regarding
          your rights and obligations, as well as restrictions and limitations that govern your
          usage of the Services. Before you use the Services, please read this Agreement carefully.
        </p>
        <p>
          BY ACCESSING, VISITING, BROWSING, USING, OR ATTEMPTING TO INTERACT WITH ANY PART OF THIS
          SERVICES, YOU WARRANT THAT YOU ARE ABOVE THE AGE OF 13 AND THAT YOU HAVE READ, UNDERSTAND,
          AND AGREE TO BE BOUND BY THIS AGREEMENT. IF YOU DO NOT AGREE TO BE BOUND BY THIS
          AGREEMENT, DO NOT ACCESS OR USE ANY PART OF THE SERVICES.
        </p>
        <p>
          WE RESERVE THE RIGHT, FROM TIME TO TIME, WITH OR WITHOUT NOTICE TO YOU, TO MAKE CHANGES TO
          THIS AGREEMENT, IN OUR SOLE DISCRETION. CONTINUED USE OF ANY PART OF THE SERVICES
          CONSTITUTES YOUR ACCEPTANCE OF SUCH CHANGES. THE MOST CURRENT VERSION OF THIS AGREEMENT,
          WHICH SUPERSEDES ALL PREVIOUS VERSIONS, CAN BE REVIEWED BY CLICKING ON OUR “TERMS OF USE”
          LINK ON OUR WEBSITE.
        </p>
        <span className="Terms-sectionHeader">2. TERMS APPLICABLE TO BUYERS</span>
        <p>
          When you commission an original pictorial work (an “Artwork”) through the Services, unless
          you and the commissioned artist enter into an agreement otherwise, all intellectual
          property rights, including any copyright or trademark rights in the Artwork shall be
          retained by the artist. All rights not expressly granted under this Agreement are reserved
          to the artist.
        </p>
        <p>
          Your purchase of an Artwork through the Services entitled you to a non-exclusive,
          perpetual, and royalty-free license to use and display the Artwork for your own personal,
          non-commercial use (e.g., as wallpaper on your personal electronic devices or for private
          display in your home). Unless agreed otherwise with the artist, you shall not be entitled
          to commercially exploit the Artwork via any manner or means now or hereafter known,
          including, but not limited to any of the following:
        </p>
        <ul>
          <li>The design, manufacture, or sale of any consumer products or merchandise;</li>

          <li>
            The marketing, advertising, or promotion of either your own or any third party product
            or service; or
          </li>

          <li>
            The modification, adaptation, reproduction, or distribution of the Artwork or the
            creation of derivative copyrighted works based on the Artwork of any kind whatsoever.
          </li>
        </ul>
        <span className="Terms-sectionHeader">3. TERMS APPLICABLE TO SELLERS</span>
        <p>
          For purposes of allowing us to offer your Artwork for sale through our Services, you agree
          to grant a non-exclusive, royalty-free license for us to reproduce, adapt, modify, store,
          and publicly display your Artwork. We shall have no responsibility or liability to you for
          the deletion or failure to store your Artwork offered for sale through our Services, and
          you are solely responsible for retaining back-up copies of your Artwork.{' '}
        </p>
        <p>
          You are solely responsible for your Artwork that you upload to our Services, and you
          represent and warrant that your Artwork is your original, creative work, made without the
          assistance of generative AI tools, that will not violate the intellectual property rights,
          rights of publicity, or any other legal rights of any third party. If your Artwork would
          be considered by a reasonably prudent person to be NSFW, we ask that you respect users
          that may not wish to view such content by tagging your Artwork accordingly. To do so, you
          can edit the commission post by navigating to the &quot;Artists Hub,&quot; tapping
          &quot;Manage commissions,&quot; then editing the relevant commission post by toggling
          &quot;NSFW&quot; to true. If you are not sure if your Artwork consists of permissible
          content allowable on our Services, please reach out to us at artden.contact@gmail.com with
          any questions you may have prior to offering your Artwork for sale.{' '}
        </p>
        <p>
          You further agree that your Artwork will not depict or encourage any of the following:
        </p>
        <ul>
          <li>
            cruelty, hatred, bigotry, racism, sexism, homophobia, xenophobia, or graphic violence;
          </li>

          <li>
            defamation, invasion of privacy, threats, harassment, abuse, pornography, obscenity,
            “deep fakes,” fraud, or conduct that otherwise encourages harm to others; or
          </li>

          <li>
            any other content that a reasonably prudent person would consider to be objectionable,
            unlawful, or infringing upon the rights of others.
          </li>
        </ul>
        <p>
          If we believe your Artwork depicts or encourages any of the above, we reserve the right to
          suspend and/or terminate your access to the Services, without notice or liability to you.{' '}
        </p>
        <span className="Terms-sectionHeader">4. RESTRICTIONS ON USE OF THE SERVICES</span>
        <p>
          You may only use the Services for the purposes expressly permitted by this Agreement. You
          agree that you will not use the Services for any purpose that is unlawful or prohibited by
          this Agreement. In addition, you agree that you will not engage in any activities intended
          to disable, destroy, overburden, damage, or impair the Services or interfere with any
          other party&apos;s use and enjoyment of the Services. You agree that you will not obtain
          or attempt to obtain any materials, content, or information through any means not
          intentionally made available or provided to you through the Services.
        </p>
        <span className="Terms-sectionHeader">5. PAYMENTS, REFUNDS, AND TAXES</span>
        <p>
          Commissions to artists are paid upfront and in full before the commencement of work. You
          may only request cancellation of a commission if the work is labeled as “In progress” or
          “Pending review.” Refunds may be remitted under the following circumstances:
        </p>
        <ul>
          <li>The artist accepts your cancellation request; or</li>
          <li>Support issues a refund, determined on a case-by-case basis.</li>
        </ul>
        <p>
          Refunds offered through support depend on the extent of work that has already been
          completed by the artist prior to your cancellation request. To request a refund through
          the support option, use the contact form in your profile.{' '}
        </p>
        <p>
          We reserve the right, but not the obligation, to confirm the credibility of all payment
          transactions made through the Services. We are not obligated to process payments that we
          believe, in our sole discretion:
        </p>
        <ul>
          <li>Are deposited by fraudulent, illegal, or unauthorized means; or </li>

          <li>
            Located in a country or territory where there is a high-risk for fraudulent activity.
          </li>
        </ul>
        <p>
          Payments are processed through Stripe, our payment processor. We also collect any
          applicable sales tax for transactions completed through the Services that are due to the
          applicable governmental authorities. Sellers are solely responsible for the payment of any
          taxes due arising out of any income earned through the Services.{' '}
        </p>
        <span className="Terms-sectionHeader">6. DISCLAIMERS</span>
        <p>
          Your use of the Services is at your own risk, and the Services are provided to you “as is”
          and without warranties of any kind, either express or implied. You also acknowledge that
          we cannot guarantee that your usage of our Services will be uninterrupted or error-free,
          that defects will be corrected, or that any failure of hardware, software, internet
          access, or third-party unauthorized access will not adversely impact your access to the
          Services.{' '}
        </p>
        <span className="Terms-sectionHeader">7. LIMITATION ON LIABILITY</span>
        <p>
          BOTH WE AND OUR SUBSIDIARIES, AFFILIATES, LICENSORS, SERVICE PROVIDERS, CONTENT PROVIDERS,
          EMPLOYEES, AGENTS, OFFICERS, AND DIRECTORS WILL NOT BE LIABLE FOR ANY INCIDENTAL, DIRECT,
          INDIRECT, PUNITIVE, ACTUAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR OTHER DAMAGES, INCLUDING
          LOSS OF REVENUE OR INCOME, PAIN AND SUFFERING, EMOTIONAL DISTRESS, OR SIMILAR DAMAGES,
          EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </p>
        <span className="Terms-sectionHeader">8. INDEMNITY</span>
        <p>
          You will indemnify and hold us and our subsidiaries, affiliates, licensors, content
          providers, service providers, employees, agents, officers, directors, and contractors (the
          “Indemnified Parties”) harmless from any breach of this Agreement by you. You agree that
          the Indemnified Parties will have no liability in connection with any such breach or
          unauthorized use, and you agree to indemnify any and all resulting loss, damages,
          judgments, awards, costs, expenses, and attorney&apos;s fees of the Indemnified Parties in
          connection therewith. You will also indemnify and hold the Indemnified Parties harmless
          from and against any claims brought by third parties arising out of your use of the
          Services.
        </p>
        <span className="Terms-sectionHeader">9. COPYRIGHT INFRINGEMENT</span>
        <p>
          If you believe that any material appearing on the Services constitutes copyright
          infringement, please provide our copyright agent the written information specified below.
          Please note that this procedure is exclusively for notifying us that your copyrighted
          material has been infringed:
        </p>
        <ul>
          <li>
            An electronic or physical signature of the person authorized to act on behalf of the
            owner of the copyright interest;
          </li>

          <li>A description of the copyrighted work that you claim has been infringed upon;</li>

          <li>
            A description of where the material that you claim is infringing is located on the
            Services,
          </li>

          <li>Your full name, address, telephone number, and e-mail address;</li>

          <li>
            A statement by you that you have a good faith belief that the disputed use is not
            authorized by the copyright owner, its agent, or the law; and
          </li>

          <li>
            A statement by you, made under penalty of perjury, that the above information in your
            notice is accurate, and that you are the copyright owner or authorized to act on the
            copyright owner&apos;s behalf.
          </li>
        </ul>
        <p>
          Our copyright agent for notice of claims of copyright infringement on the Services can be
          reached at the following address:<br></br>Attn: Brianna Norwood<br></br>Copyright Agent
          <br></br>3183 Williamsburg Street<br></br>Loveland, CO 80538<br></br>
          artden.contact@gmail.com
        </p>
        <span className="Terms-sectionHeader">10. SECURITY</span>
        <p>
          We reserve the right to fully cooperate with any law enforcement authorities or court
          orders requesting or directing us to disclose the identity of anyone that is believed to
          have violated this Agreement. BY ACCEPTING THIS AGREEMENT, YOU WAIVE AND HOLD US HARMLESS
          FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY US DURING OR AS A RESULT OF
          INVESTIGATIONS AND/OR FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER
          US OR LAW ENFORCEMENT AUTHORITIES.
        </p>
        <span className="Terms-sectionHeader">11. MISCELLANEOUS</span>
        <p>
          This Agreement will be governed by and interpreted pursuant to the laws of the state of
          Washington, United States of America, notwithstanding any principles of conflicts of law.
          Any controversy or claim arising out of or relating to this Agreement, or the breach
          thereof, shall be settled by binding arbitration in Seattle, Washington. The arbitration
          shall be conducted on a confidential basis and administered by the American Arbitration
          Association in accordance with its Commercial Arbitration Rules. The award of the
          arbitrator shall be final and binding, and any judgment on the award rendered by the
          arbitrator may be entered in any court having jurisdiction thereof.
        </p>
        <p>
          If any part of this Agreement is unlawful, void, or unenforceable, that part will be
          deemed severable, and will not affect the validity and enforceability of any remaining
          provisions. You agree that no joint venture, partnership, employment, or agency
          relationship exists between you and us as a result of this Agreement or use of the
          Services.
        </p>
        <p>
          This Agreement constitutes the entire agreement between us relating to this subject matter
          and supersedes all prior or contemporaneous communications and proposals, whether
          electronic, oral, or written.
        </p>
        <p>
          A printed version of this Agreement and of any notice given in electronic form shall be
          admissible in judicial or administrative proceedings based upon or relating to this
          Agreement to the same extent and subject to the same conditions as other business
          documents and records originally generated and maintained in printed form.
        </p>
        <p>
          You agree that regardless of any applicable law to the contrary, you cannot file a claim
          or cause of action arising out of or related to the Services or this Agreement more than
          one (1) year after such claim or cause of action arose.
        </p>
        <p>We reserve the right to revise this Agreement at any time, by updating this posting.</p>
        <Spacer5 />
      </div>
    </div>
  );
}
