import React, { ChangeEvent, useState } from 'react';
import './PostEditor.page.css';
import '../shared/shared.css';
import { Spacer20, Spacer5, ImageUpload, Divider } from '../shared/shared';
import { DateTimeUtil, Post, TextUtil } from 'artden-common';
import { useIsMobile } from '../shared/useIsMobile';
import { PostContentRender } from '../post/Post.page';
import { BlogRow } from '../blog/Blog.page';
import { useNavigate } from 'react-router-dom';

export function PostEditorPage(): JSX.Element {
  return (
    <div>
      <PostEditorPanel />
    </div>
  );
}

function PostEditorPanel(): JSX.Element {
  const isMobile = useIsMobile();

  return (
    <div className={`${isMobile ? 'BorderSizing Padding-Horizontal-5' : 'Panel-padding'}`}>
      {isMobile ? <Spacer5 /> : <Spacer20 />}
      <div className={`${isMobile ? '' : 'GreyBorder Padding-15'}`}>
        <PostEditorContent />
      </div>
      <Spacer20 />
    </div>
  );
}

function PostEditorContent(): JSX.Element {
  const navigate = useNavigate();
  const [post, setPost] = useState<Post>({
    id: 1,
    title: '',
    body: '',
    createdAt: DateTimeUtil.now(),
    author: 'Brianna Norwood',
    profileImage: 'brianna.png',
    wordCount: 0,
  });
  const [coverImageData, setCoverImageData] = useState('');
  const [bodyText, setBodyText] = useState('');
  const [titleText, setTitleText] = useState('');
  const [authorText, setAuthorText] = useState('Brianna Norwood');

  const handleChangeBody = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setBodyText(e.target.value);
    setPost({
      ...post,
      body: e.target.value,
      wordCount: TextUtil.countWordsInHtml(e.target.value),
    });
  };

  const handleChangeTitle = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTitleText(e.target.value);
    setPost({
      ...post,
      title: e.target.value,
    });
  };

  const handleChangeAuthor = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setAuthorText(e.target.value);
    setPost({
      ...post,
      author: e.target.value,
    });
  };

  const handleButtonClick = async () => {
    const url = '/internalapi/newpost';
    const data = {
      title: titleText,
      body: bodyText,
      author: authorText,
      coverImage: coverImageData,
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        alert('ERROR: ' + response.statusText);
        throw new Error('Network response was not ok ' + response.statusText);
      }

      alert('Post made successfully!');
      navigate('/');
    } catch (error) {
      alert(error);
      console.error('Error:', error);
    }
  };

  return (
    <div>
      <div>
        <label>Cover Image</label>
        <ImageUpload onChooseImage={setCoverImageData} />
      </div>
      <div className="FillWidth">
        <label htmlFor="author">Author</label>
        <input
          type="text"
          id="author"
          name="author"
          value={authorText}
          onChange={handleChangeAuthor}
          required
        />
      </div>
      <div className="FillWidth">
        <label htmlFor="title">Title</label>
        <input
          type="text"
          id="title"
          name="title"
          value={titleText}
          onChange={handleChangeTitle}
          required
        />
      </div>
      <div className="FillWidth">
        <label htmlFor="body">Body</label>
        <textarea
          className="FillWidth"
          id="body"
          name="body"
          rows={16}
          value={bodyText}
          onChange={handleChangeBody}
          required
        ></textarea>
      </div>
      <div>
        <button className={`button-style FillWidth`} onClick={handleButtonClick}>
          POST
        </button>
      </div>
      <Spacer5 />
      <Divider />
      <Spacer5 />
      <BlogRow post={post} imageOverride={coverImageData} />
      <Spacer5 />
      <PostContentRender post={post} />
    </div>
  );
}
