import React, { ChangeEvent, FormEvent, useState } from 'react';
import './Contact.page.css';
import '../shared/shared.css';
import { Spacer10, Spacer20, Spacer4, Spacer5 } from '../shared/shared';
import { Link, useNavigate } from 'react-router-dom';
import { useIsMobile } from '../shared/useIsMobile';

export function ContactPage(): JSX.Element {
  return (
    <div>
      <ContactPanel />
    </div>
  );
}

function ContactPanel(): JSX.Element {
  const isMobile = useIsMobile();
  return (
    <div
      className={`Contact-container FlexColumn FlexAlignCenter ${
        isMobile ? 'BorderSizing Padding-Horizontal-5' : 'FillWidth Flex FlexJustifyCenter'
      }`}
    >
      <div className={`${isMobile ? '' : 'MaxColumnWidth Flex FlexJustifyCenter'}`}>
        <div className={`${isMobile ? '' : 'MaxWidth-500px MinWidth-350px'}`}>
          {isMobile ? <Spacer5 /> : <Spacer20 />}
          <span className="Panel-header">Support</span>
          <DiscordSection />
          <Spacer10 />
          <SupportSection />
          <Spacer20 />
        </div>
      </div>
    </div>
  );
}

function DiscordSection(): JSX.Element {
  return (
    <div>
      <Spacer5 />
      <div className="FlexColumn FlexAlignCenter DiscordBackgroundColor BorderSizing Padding-10 RoundedCorners-2 CenterText">
        <a
          href="https://discord.gg/BCf4Y2PXdQ"
          target="_blank"
          rel="noopener noreferrer"
          className="FlexEqualWeight"
        >
          <img className="Width-40 BorderSizing" alt="Discord" src={`/images/discordlogo.png`} />
        </a>
        <div className="FlexEqualWeight WhiteColor">
          Reach us on{' '}
          <a
            className="WhiteColor"
            href="https://discord.gg/BCf4Y2PXdQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            Discord
          </a>{' '}
          to chat with us live or chat with other artists!
        </div>
      </div>
    </div>
  );
}

type FormData = {
  name: string;
  username: string;
  email: string;
  message: string;
};

function SupportSection(): JSX.Element {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [formData, setFormData] = useState<FormData>({
    name: '',
    username: '',
    email: '',
    message: '',
  });
  const [isSending, setIsSending] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSending(true);
    try {
      const response = await fetch('/api/contactus', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        navigate('/support-thanks');
      } else {
        alert('Something went wrong!');
        setIsSending(false);
      }
    } catch (error) {
      alert('Something went wrong!');
      console.error('Failed to send form data:', error);
      setIsSending(false);
    }
  };

  return (
    <div>
      <span className="Panel-header">Contact Us</span>
      {isSending ? (
        <div className="FillWidth Flex Flex FlexJustifyCenter Padding-10">
          <div className="Shared-LoadingSpinner"></div>
        </div>
      ) : (
        <>
          <p>
            Check out our <Link to="/faq">FAQ</Link> or use the form below to reach out
          </p>
          <form onSubmit={handleSubmit} className="FillWidth">
            <div className="FlexRow Gap-4">
              <div className="FlexColumn FlexEqualWeight">
                <input
                  placeholder="First Name*"
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="FlexColumn FlexEqualWeight">
                <input
                  placeholder="Username"
                  type="text"
                  id="username"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                />
              </div>
            </div>
            <Spacer4 />
            <div className="FlexColumn">
              <input
                placeholder="Email*"
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <Spacer4 />
            <div className="FlexColumn">
              <textarea
                placeholder="Message*"
                id="message"
                name="message"
                rows={4}
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <Spacer4 />
            <button
              className={`button-style Padding-Right-10 Padding-Left-10 ${
                isMobile ? 'FillWidth' : ''
              }`}
              type="submit"
            >
              Send
            </button>
          </form>
        </>
      )}
    </div>
  );
}
